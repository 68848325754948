export default Object.freeze({
  getBlob: {
    GET: () => {
      return `blobs`;
    },
  },
  getBlobSaleOffersAnnouncements: {
    GET: () => {
      return `blobs/saleOffer`;
    },
  },
  getBlobSaleMaritalStatus: {
    GET: () => {
      return `blobs/maritalStatus`;
    },
  },
  getBlobAnnouncement: {
    GET: () => {
      return `blobs/announcement`;
    },
  },
  deleteBlob: {
    GET: (fileName) => {
      return `blobs/${fileName}`;
    },
  },
});
