<template>
  <div>
    <ValidationObserver ref="form">
      <div class="columns">
        <div class="column">
          <ValidationProvider name="Titlu" rules="required" v-slot="{ errors }">
            <b-field
              :type="errors[0] ? 'is-danger' : ''"
              label="Titlu"
              :message="errors[0]"
            >
              <b-input v-model="announcement.title" type="textarea" required />
            </b-field>
          </ValidationProvider>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <ValidationProvider
            name="Tip anunt"
            rules="required"
            v-slot="{ errors }"
          >
            <b-field
              :type="errors[0] ? 'is-danger' : ''"
              label="Tip Anunt *"
              :message="errors[0]"
            >
              <b-select
                v-model="announcement.announcementTypeId"
                placeholder="Selectati un tip de anunt"
                required
                expanded
              >
                <option
                  v-for="(announcementType, index) in announcementTypes"
                  :key="index"
                  :value="announcementType.id"
                >
                  {{ announcementType.description }}
                </option>
              </b-select>
            </b-field>
          </ValidationProvider>
        </div>
        <div class="column">
          <ValidationProvider name="Data" rules="required" v-slot="{ errors }">
            <b-field
              :type="errors[0] ? 'is-danger' : ''"
              label="Data publicarii"
              :message="errors[0]"
            >
              <b-datepicker
                :date-parser="parser"
                v-model="announcement.date"
                locale="ro"
                :unselectable-days-of-week="[0, 6]"
              >
              </b-datepicker>
            </b-field>
          </ValidationProvider>
        </div>
        <div class="column">
          <b-field label="Document">
            <b-field class="file is-primary" :class="{ 'has-name': !!file }">
              <b-upload v-model="file" class="file-label" rounded>
                <span class="file-cta">
                  <b-icon class="file-icon" icon="upload"></b-icon>
                  <span class="file-label">{{
                    file.name || "Click pentru a incarca documentul dorit"
                  }}</span>
                </span>
              </b-upload>
            </b-field>
          </b-field>
        </div>
        <div class="column">
          <ValidationProvider
            name="Visible"
            rules="required"
            v-slot="{ errors }"
          >
            <b-field
              :type="errors[0] ? 'is-danger' : ''"
              label="Activ"
              :message="errors[0]"
            >
              <b-checkbox v-model="announcement.visible">Activ</b-checkbox>
            </b-field>
          </ValidationProvider>
        </div>
      </div>
    </ValidationObserver>
    <div class="columns">
      <div class="column">
        <ValidationProvider name="Description" v-slot="{ errors }">
          <b-field
            :type="errors[0] ? 'is-danger' : ''"
            label="Descriere"
            :message="errors[0]"
          >
            <b-input v-model="announcement.description" type="textarea" />
          </b-field>
        </ValidationProvider>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <b-field grouped>
          <div class="control">
            <b-button
              type="is-primary"
              native-type="submit"
              @click="handleAddAnnouncement"
              >Salvare</b-button
            >
          </div>
          <div class="control">
            <b-button type="is-danger is-outlined" @click="cancel()"
              >Anulare</b-button
            >
          </div>
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
import { rawObject } from "@/helpers/utils";
import { pathOr } from "ramda";
import Announcements from "@/services/announcements.service";
import BlobsService from "@/services/blobs.service";
import { blobMixin } from "../../mixins/blobMixin";
import moment from "moment";

export default {
  name: "AnnouncementsAdd",
  mixins: [blobMixin],
  props: {
    accept: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      editMode: false,
      id: 0,
      file: {
        name: null,
      },
      announcementTypes: [],
      announcement: {
        title: null,
        date: null,
        uri: null,
        description: null,
        visible: true,
        announcementTypeId: null,
      },
    };
  },
  methods: {
    async getAnnouncementTypes() {
      const { getAllAnnouncementTypes } = Announcements;
      getAllAnnouncementTypes().then((response) => {
        const { data } = response;
        this.announcementTypes = data;
      });
    },
    async uploadFile(announcementTypeId) {
      const { handleUpload } = BlobsService;
      await handleUpload(
        this.file,
        announcementTypeId,
        this.announcement.date
      ).then((response) => {
        const { data } = response;
        this.announcement.uri = data;
        return data;
      });
    },
    async deleteFile(fileName) {
      const { handleDelete } = BlobsService;
      const response = await handleDelete(fileName);
      const { data } = response;
      this.announcement.uri = null;
      return data;
    },
    parser(d) {
      return new Date(Date.parse(d));
    },
    handleAddAnnouncement() {
      this.$refs.form.validate().then((success) => {
        if (!success) {
          return;
        }
        if (this.editMode) {
          if (this.announcement.date) {
            this.announcement.date = new Date(
              this.announcement.date.getTime() -
                this.announcement.date.getTimezoneOffset() * 60000
            ).toJSON();
          }
          this.$emit("handleEditAnnouncement", rawObject(this.announcement));
          if (this.file.name != this.announcement.uri) {
            let fileToBeDeleted = this.getFileNameFromBlob(
              this.announcement.uri
            );
            this.deleteFile(fileToBeDeleted);
            this.uploadFile(this.announcement.announcementTypeId).then(
              (response) => {
                this.$emit(
                  "handleEditAnnouncement",
                  rawObject(this.announcement)
                );
              }
            );
          }
        } else {
          if (this.announcement.date) {
            this.announcement.date = new Date(
              this.announcement.date.getTime() -
                this.announcement.date.getTimezoneOffset() * 60000
            ).toJSON();
          }
          this.uploadFile(this.announcement.announcementTypeId).then(
            (response) => {
              this.$emit("handleAddAnnouncement", rawObject(this.announcement));
            }
          );
        }
      });
    },
    async getAnnouncement(id) {
      const { handleGetAnnouncement } = Announcements;
      const response = await handleGetAnnouncement(id);
      const { data } = response;
      this.file.name = this.getFileNameFromBlob(data.uri);
      return data;
    },
    cancel() {
      if (this.editMode == false && this.announcement.uri != null) {
        this.deleteFile(this.file.name);
      }
      this.$router.push({ path: "/Announcements" });
    },
  },
  created() {
    this.getAnnouncementTypes();
    const id = parseInt(pathOr(null, ["params", "id"], this?.$route));
    this.id = id;
    if (this.id) {
      this.editMode = true;
      this.getAnnouncement(id).then((response) => {
        this.announcement = response;
        this.announcementType = response.announcementType;
        this.announcement.date = new Date(this.announcement.date);
      });
    }
  },
};
</script>
