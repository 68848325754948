<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <section class="section is-main-section">
      <card-component
        :title="formCardTitle"
        icon="file-edit"
        class="tile is-child"
      >
        <announcements-add
          @handleAddAnnouncement="handleAddAnnouncement"
          @handleEditAnnouncement="handleEditAnnouncement"
        />
      </card-component>
    </section>
  </div>
</template>

<script>
import { pathOr } from "ramda";
import Announcements from "@/services/announcements.service";
import TitleBar from "@/components/TitleBar";
import CardComponent from "@/components/CardComponent";
import AnnouncementsAdd from "../components/announcements/announcements-add.vue";

export default {
  name: "Announcement",
  components: { TitleBar, CardComponent, AnnouncementsAdd },
  data() {
    return {
      editMode: false,
    };
  },
  methods: {
    async handleAddAnnouncement(announcement) {
      const { handleAddAnnouncement } = Announcements;
      const response = await handleAddAnnouncement(announcement);
      const { data, status } = response;
      if (status == "200") {
        this.$router.push({ path: "/Announcements" });
      } else {
      }
    },
    async handleEditAnnouncement(announcement) {
      const { handleEditAnnouncement } = Announcements;
      const response = await handleEditAnnouncement(announcement);
      this.$router.push({ path: "/Announcements" });
    },
  },
  computed: {
    titleStack() {
      if (this.editMode) {
        return ["Anunturi", "Editare anunt"];
      } else {
        return ["Anunturi", "Adaugare anunt"];
      }
    },
    formCardTitle() {
      if (this.editMode) {
        return "Editare anunt";
      } else {
        return "Adaugare anunt";
      }
    },
  },
  created() {
    const id = parseInt(pathOr(null, ["params", "id"], this?.$route));
    if (id) {
      this.editMode = true;
    } else {
      this.announcement.date = new Date();
    }
  },
};
</script>
