var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{ref:"form"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('ValidationProvider',{attrs:{"name":"Titlu","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors[0] ? 'is-danger' : '',"label":"Titlu","message":errors[0]}},[_c('b-input',{attrs:{"type":"textarea","required":""},model:{value:(_vm.announcement.title),callback:function ($$v) {_vm.$set(_vm.announcement, "title", $$v)},expression:"announcement.title"}})],1)]}}])})],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('ValidationProvider',{attrs:{"name":"Tip anunt","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors[0] ? 'is-danger' : '',"label":"Tip Anunt *","message":errors[0]}},[_c('b-select',{attrs:{"placeholder":"Selectati un tip de anunt","required":"","expanded":""},model:{value:(_vm.announcement.announcementTypeId),callback:function ($$v) {_vm.$set(_vm.announcement, "announcementTypeId", $$v)},expression:"announcement.announcementTypeId"}},_vm._l((_vm.announcementTypes),function(announcementType,index){return _c('option',{key:index,domProps:{"value":announcementType.id}},[_vm._v(" "+_vm._s(announcementType.description)+" ")])}),0)],1)]}}])})],1),_c('div',{staticClass:"column"},[_c('ValidationProvider',{attrs:{"name":"Data","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors[0] ? 'is-danger' : '',"label":"Data publicarii","message":errors[0]}},[_c('b-datepicker',{attrs:{"date-parser":_vm.parser,"locale":"ro","unselectable-days-of-week":[0, 6]},model:{value:(_vm.announcement.date),callback:function ($$v) {_vm.$set(_vm.announcement, "date", $$v)},expression:"announcement.date"}})],1)]}}])})],1),_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Document"}},[_c('b-field',{staticClass:"file is-primary",class:{ 'has-name': !!_vm.file }},[_c('b-upload',{staticClass:"file-label",attrs:{"rounded":""},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}},[_c('span',{staticClass:"file-cta"},[_c('b-icon',{staticClass:"file-icon",attrs:{"icon":"upload"}}),_c('span',{staticClass:"file-label"},[_vm._v(_vm._s(_vm.file.name || "Click pentru a incarca documentul dorit"))])],1)])],1)],1)],1),_c('div',{staticClass:"column"},[_c('ValidationProvider',{attrs:{"name":"Visible","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors[0] ? 'is-danger' : '',"label":"Activ","message":errors[0]}},[_c('b-checkbox',{model:{value:(_vm.announcement.visible),callback:function ($$v) {_vm.$set(_vm.announcement, "visible", $$v)},expression:"announcement.visible"}},[_vm._v("Activ")])],1)]}}])})],1)])]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('ValidationProvider',{attrs:{"name":"Description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors[0] ? 'is-danger' : '',"label":"Descriere","message":errors[0]}},[_c('b-input',{attrs:{"type":"textarea"},model:{value:(_vm.announcement.description),callback:function ($$v) {_vm.$set(_vm.announcement, "description", $$v)},expression:"announcement.description"}})],1)]}}])})],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"grouped":""}},[_c('div',{staticClass:"control"},[_c('b-button',{attrs:{"type":"is-primary","native-type":"submit"},on:{"click":_vm.handleAddAnnouncement}},[_vm._v("Salvare")])],1),_c('div',{staticClass:"control"},[_c('b-button',{attrs:{"type":"is-danger is-outlined"},on:{"click":function($event){return _vm.cancel()}}},[_vm._v("Anulare")])],1)])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }