export const blobMixin = {
  methods: {
    getFileNameFromBlob(blob) {
      let fileName = blob.substring(
        blob.indexOf("announcementsmaritalstatus") +
          "announcementsmaritalstatus".length +
          1
      );
      return fileName;
    },
  },
};
