import blobEndpoint from "./endpoints/blob-endpoints";
import apiConfig from "../config/api";
import axios from "axios";
const { baseURL } = apiConfig;

const {
  deleteBlob,
  getBlobSaleOffersAnnouncements,
  getBlobSaleMaritalStatus,
  getBlobAnnouncement
} = blobEndpoint;

const BlobsService = {
  async handleUploadSaleOffersAnnouncement(file) {
    let formData = new FormData();
    formData.append("file", file);
    const uploadUrl = `${baseURL}${getBlobSaleOffersAnnouncements.GET()}`;
    return await axios
      .post(uploadUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  async handleUpload(file, announcementTypeId, publishDate) {
    let formData = new FormData();
    formData.append("file", file);
    formData.append('announcementTypeId', announcementTypeId);
    formData.append('publishDate', publishDate);
    const uploadUrl = `${baseURL}${getBlobAnnouncement.GET()}`;
    return await axios
      .post(uploadUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  async handleDelete(fileName) {
    const getDeleteBlobUrl = `${baseURL}${deleteBlob.GET(fileName)}`;
    return await axios
      .delete(
        getDeleteBlobUrl,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .catch((error) => {
        return error;
      });
  },
};

export default BlobsService;
